@import "~@fontsource/open-sans/300.css";
@import "~@fontsource/open-sans/400.css";
@import "~@fontsource/open-sans/500.css";
@import "~@fontsource/open-sans/600.css";
@import "~@fontsource/open-sans/700.css";
@import "~@fontsource/open-sans/800.css";

body {
  font-family: Open Sans, sans-serif;
  padding: 0;
  margin: 0;
}

.image-gallery-slide img {
  width: 100%;
  height: 300px;
}

.image-gallery-swipe,
.image-gallery-slides {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.image-gallery-slide {
  background-color: #f7f7f7;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.image-gallery-thumbnails {
  background-color: #ededed;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover {
  border: 2px solid #333 !important;
}

.image-gallery-left-nav {
  margin-left: 15px !important;
}

.image-gallery-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.75) !important;
  padding: 0 !important;
  filter: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-gallery-icon:hover {
  background-color: rgba(0, 0, 0, 1) !important;
  color: #fff !important;
}

.image-gallery-right-nav {
  margin-right: 15px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 0.3em solid rgba(0, 0, 0, 0.1);
  border-top: 0.3em solid #000;
  border-radius: 50%;
  width: 2.28571429rem;
  height: 2.28571429rem;
  animation: spin 0.6s linear infinite;
}

.navOverlay {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 98;
  background-color: rgba(255, 255, 255, 0.97);
}

.headerContent {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 970px;
  padding: 15px;
  background-color: #fff;
}

.navLinkContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navLink {
  display: block;
  font-size: 14px;
  margin-left: 15px;
  font-weight: 500;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
}

.navLink:hover {
  color: rgba(0, 0, 0, 1);
}

.navLink::after {
  content: "";
  display: block;
  margin-top: 3px;
  height: 2px;
  width: 0;
  background-color: rgba(0, 0, 0, 1);
  transition: width 0.3s ease-in-out;
}

.navLink:hover::after {
  width: 20px;
}

.navLink.active {
  color: rgba(0, 0, 0, 1);
}

.navLink.active::after {
  width: 20px;
}

@media screen and (max-width: 767px) {
  .showDesktop {
    display: none;
  }
  .showMobile {
    display: block;
  }
  .headerContent {
    justify-content: start;
    padding: 15px 15px 15px 0;
  }
  .navLinkContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  .navLink {
    font-size: 18px;
    margin-bottom: 25px;
    margin-left: 47px;
  }
}

@media screen and (min-width: 768px) {
  .navOverlay {
    display: none;
  }
  .showDesktop {
    display: block;
  }
  .showMobile {
    display: none;
  }
}
